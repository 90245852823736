import { EventBus } from '@/assets/static/event-bus.js'
import Logo from '@/components/icons/logo_white_complete'
import Shop from '@/components/icons/shop'
import Close from '@/components/icons/close'
import Less from '@/components/icons/cart_less'
import More from '@/components/icons/cart_more'
import CloseMobile from '@/components/icons/close_mobile'
import LessMobile from '@/components/icons/less_mobile'
import MoreMobile from '@/components/icons/more_mobile'
import ArrowRight from '@/components/icons/arrow_right'
import MenuMobile from '@/components/icons/menu_mobile'
import Wlc from '@/components/icons/wlc'
import youtube from '@/components/icons/youtube'
import facebook from '@/components/icons/facebook'
import instagram from '@/components/icons/instagram'
import twitter from '@/components/icons/twitter'
import close from '@/components/icons/close'
import axios from 'axios';
import apiKirby from '@/config/kirby';


import shopify from '@/mixins/shopify'
import cart from '@/mixins/cart'

export default {
  name: 'Header',

  mixins: [
    shopify,
    cart
  ],

  components: {
    Logo,
    Shop,
    Close,
    Less,
    More,
    ArrowRight,
    MenuMobile,
    Wlc,
    facebook,
    youtube,
    instagram,
    twitter,
    close,
    CloseMobile,
    LessMobile,
    MoreMobile
  },

  data() {
    return {
      isCart : false,
      menu_mobile: false,
      sublistAbout: false,
      youtube: '',
      twitter: '',
      facebook: '',
      instagram: '',
    }
  },

  computed: {
    cartItems: function() {
      return this.cart.length;
    },

    subtotal: function() {
      let subtotal = 0;

      this.cart.forEach(item => {
        subtotal += item.unitPrice;
      });

      return subtotal;
    }
  },

  methods: {
    sublist: function(opc) {
      switch (opc) {
        case 'about':
            this.sublistAbout = !this.sublistAbout;
          break;

        default:
          break;
      }
    },

    showCart: function() {
      this.getCart();

      this.isCart = true;
    },

    decCartItem: async function(key) {
      let id = this.cart[key].id;
      let quantity = this.cart[key].quantity - 1;

      await this.updateCheckoutItem(id, quantity);
      this.updateCartItem(id, quantity);
    },

    incCartItem: async function(key) {
      let id = this.cart[key].id;
      let quantity = this.cart[key].quantity + 1;

      await this.updateCheckoutItem(id, quantity);
      this.updateCartItem(id, quantity);
    },

    getSocialInfo: async function() {
      let social;
      await axios
        .get(apiKirby + 'api/social.json')
        .then(res => social = res.data || [])
        .catch(err => {return});
      
      if(social) {
        this.facebook = social.facebook;
        this.instagram = social.instagram;
        this.twitter = social.twitter;
        this.youtube = social.youtube;
      }
    },
  },

  mounted() {
    this.getSocialInfo();

    EventBus.$on('showCart', () => {
      this.showCart();
    });
  },

  filters: {
    money: function (val) {
      let formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumFractionDigits: 0
      });

      return formatter.format(val);
    }
  }
}
