import axios from 'axios'
import shopify from '@/config/shopify'

import Client from 'shopify-buy'

const client = Client.buildClient({
  domain: shopify.store,
  storefrontAccessToken: shopify.token
})

export default {
  data() {
    return {
      checkout: null
    }
  },

  methods: {
    createCheckout: async function() {
      await client.checkout
        .create()
        .then(response => this.checkout = response);

      localStorage.setItem(shopify.localStorage, this.checkout.id);
    },

    getCheckout: async function() {
      let id = localStorage.getItem(shopify.localStorage) || null;

      if (id === null) {
        this.createCheckout();
      } else {
        await client.checkout
          .fetch(id)
          .then(response => this.checkout = response);

        if (this.checkout === null || this.checkout.completedAt !== null) {
          this.createCheckout();
        }
      }
    },

    getShopifyProductId: function(id) {
      return btoa('gid://shopify/Product/' + id);
    },

    getShopifyProduct: async function(id) {
      let product;

      await client.product
        .fetch(id)
        .then(response => product = response);

      return product;
    },

    addCheckoutItem: async function(lineItem) {
      await client.checkout
        .addLineItems(this.checkout.id, [lineItem])
        .then(response => this.checkout = response);

      return this.checkout.lineItems.pop().id;
    },

    updateCheckoutItem: async function(id, quantity) {
      let lineItems = [{
        id: id,
        quantity: quantity
      }];

      await client.checkout
        .updateLineItems(this.checkout.id, lineItems)
        .then(response => this.checkout = response);
    },

    removeCheckoutItem: async function(id) {
      await client.checkout
        .removeLineItems(this.checkout.id, [id])
        .then(response => this.checkout = response);
    },

    goCheckout: async function() {
      if (this.checkout !== null) {
        window.location.replace(this.checkout.webUrl);
      }
    }
  },

  mounted: async function() {
    await this.getCheckout();
  }
}
