import cart from '@/config/cart'

export default {
  data() {
    return {
      cart: []
    }
  },

  methods: {
    createCart: function() {
      this.cart = [];

      localStorage.setItem(cart.localStorage, JSON.stringify(this.cart));
    },

    getCart: function() {
      let data = localStorage.getItem(cart.localStorage) || null;

      if (data === null) {
        this.createCart();
      } else {
        this.cart = JSON.parse(data);
      }
    },

    addCartItem: function(item) {
      this.cart.push(item);

      localStorage.setItem(cart.localStorage, JSON.stringify(this.cart));
    },

    updateCartItem: function(id, quantity) {
      let cartUpdated = [];

      this.cart.forEach(item => {
        if (item.id !== id) {
          cartUpdated.push(item);
        } else if (quantity > 0) {
          item.quantity = quantity;
          cartUpdated.push(item);
        }
      });

      this.cart = cartUpdated;

      localStorage.setItem(cart.localStorage, JSON.stringify(this.cart));
    },

    removeCartItem: async function(id) {
      let cartUpdated = [];

      this.cart.forEach(item => {
        if (item.id !== id) {
          cartUpdated.push(item);
        }
      });

      this.cart = cartUpdated;

      localStorage.setItem(cart.localStorage, JSON.stringify(this.cart));
    }
  },

  mounted: async function() {
    await this.getCart();
  }
}
