export default {
  name: 'Footer',

  components: {},

  data() {
    return {
      
    }
  }
}
