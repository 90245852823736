import Header from '@/components/header'
import Footer from '@/components/footer'

export default {
  name: 'App',

  components: {
    Header,
    Footer
  }
}
