import Vue from 'vue'

// import cors from 'cors';
// Vue.use(cors)

import vuescroll from 'vuescroll';
Vue.use(vuescroll, {
  ops: {
    // The global config
  },
  name: 'myScroll' // customize component name, default -> vueScroll
});
Vue.prototype.$vuescrollConfig = {
  bar: {
    background: '#A08456'
  }
};

import { ColorPicker, ColorPanel } from 'one-colorpicker'
Vue.use(ColorPanel)
Vue.use(ColorPicker)

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
Vue.use(VueToast, {
  queue: true,
  position: 'top',
  duration: 3000,
  dismissible: true
})

import VueCollapse from 'vue2-collapse'
Vue.use(VueCollapse); 


import InlineSvg from 'vue-inline-svg'
Vue.component('inline-svg', InlineSvg)

import App from '@/views/app'
import router from '@/router'
import '@/assets/static'

Vue.config.productionTip = true;

new Vue({
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app');
