import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: function () {
        return import('../views/home');
      }
    },
    {
      path: '/about/:section',
      name: 'about',
      component: function () {
        return import('../views/about');
      }
    },
    {
      path: '/mixologia',
      name: 'mixologia',
      component: function () {
        return import('../views/mixologia');
      }
    },
    {
      path: '/products',
      name: 'products',
      component: function () {
        return import('../views/products');
      }
    },
    {
      path: '/custom',
      name: 'custom',
      component: function () {
        return import('../views/custom');
      }
    },
    {
      path: '/legals',
      name: 'legals',
      component: function () {
        return import('../views/legals');
      }
    }
  ]
});
